import React, { useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap'
import AuthForm from './AuthForm';

export default function AuthModal(props){

    return (
        <Modal  show={props.show} onHide={props.handleClose} centered size="lg">
            <Modal.Header className="d-flex justify-content-end align-items-center">
                <div onClick={props.handleClose} className="px-2 close-btn">&times;</div>
            </Modal.Header>
            <Modal.Body>
                <AuthForm hideName handleSubmit={props.handleLogin} />
                <Button block variant="primary" onClick={props.handleLogin}>
                    {props.isLoading ? <Spinner animation="border" size="sm" role="status"></Spinner>: 'Login'}
                </Button>
            </Modal.Body>
        </Modal>
    )
}