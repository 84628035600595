import React from 'react';
import { Link } from 'gatsby'
import { Element } from 'react-scroll';

export default function Footer(props) {
    return (
        <footer className="footer text-center">
            <div>
                <Link to="/terms-of-use" className="text-light">Terms Of Use</Link>
            </div>
            <div className="text-white h6">
                <Element name="contact-us">
                    <small>
                        <a href="mailto:info@eloncoin.sale" className="text-light">info@eloncoin.sale</a> | (872) ELON420
                    </small>
                </Element>
            </div>
        </footer>
    )
}