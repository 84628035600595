import firebase from "firebase/app";
import "firebase/auth";
import config from './config';
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'


export default {
    initUI(){
        const instance = firebaseui.auth.AuthUI.getInstance();
        if(instance){
            return instance;
        } else {
            return new firebaseui.auth.AuthUI(firebase.auth());
        }

    },
    init(){
        if(firebase.apps.length === 0){
            firebase.initializeApp(config);
            if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
                firebase.auth().useEmulator("http://localhost:9099");
            }
        }
    },
    instance: firebase
};