import React, { useEffect, useState } from "react"
import { Link, navigate } from "gatsby"
import { Navbar, Nav } from "react-bootstrap"
import firebase from '../services/firebase';
import { useSelector, useDispatch } from 'react-redux';
import { setIsAuthenticated, setUser, init as initAuth, logout, signIn } from '../store/actions/auth';
import { scroller } from 'react-scroll';
import AuthModal from "./AuthModal"


const Header = () => {

    const dispatch = useDispatch();
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated);
    const isAuthLoading = useSelector(state => state.auth.isLoading);
    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => setShowModal(true);
    const handleLogout = () => {
        firebase.instance.auth().signOut().then(function () {
            dispatch(logout());
            navigate('/')
        }, (error) => { });

    }
    const handleCloseModal = () => setShowModal(false);
    const handleLogin = () => {
        dispatch(signIn()).then((res) => {
            if (res === 'ok') {
                handleCloseModal();
                navigate('/my-wallet')
            }
        })
    }
    const scrollToContact = () => {
        scroller.scrollTo('contact-us', {
            duration: 1200,
            delay: 0,
            smooth: 'easeInOut'
        })
    }

    return (
        <>
            <Navbar collapseOnSelect expand="md"  bg="primary" variant="dark">
                <Navbar.Toggle className="order-2 ml-auto" aria-controls="responsive-navbar-nav" />
                <Nav className="order-md-3 order-1">
                    {!isAuthenticated
                        ? <Nav.Link onClick={handleOpenModal} title="Login">LOGIN</Nav.Link>
                        : <Nav.Link onClick={handleLogout} title="Logout">LOGOUT</Nav.Link>
                    }
                </Nav>
                <Navbar.Collapse id="responsive-navbar-nav" className="mr-auto order-3 order-md-1">
                    <Nav  className="mr-auto">
                        <Link className="nav-link" to="/">HOME</Link>
                        <Link className="nav-link" to="/about">ABOUT US</Link>
                        {isAuthenticated ? 
                            <Link className="nav-link" to="/my-wallet">MY WALLET</Link> : 
                            <Nav.Link className="nav-link" onClick={handleOpenModal}>MY WALLET</Nav.Link>  }
                        
                        {/* <Nav.Link href="mailto:info@eloncoin.sale">CONTACT US</Nav.Link> */}
                        <Nav.Link onClick={scrollToContact}>CONTACT US</Nav.Link>
                    </Nav>
                </Navbar.Collapse>

   
            </Navbar>
            <AuthModal handleLogin={handleLogin} show={showModal} isLoading={isAuthLoading} handleClose={handleCloseModal} />
        </>
    )
}

export default Header