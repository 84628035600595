import action_types from '../actionTypes';
import firebase from '../../services/firebase';
import store from '../index';
import _ from 'lodash';
import {fetchProfile} from '../actions/info' 
import { putMessage } from './message';
export function setLoading(flag) {
    return {
        type: action_types.AUTH_SET_LOADING,
        flag
    }
}

export function setFormValue(key, value) {
    return {
        type: action_types.AUTH_SET_FORM_VALUE,
        key, 
        value
    }
}

export function setIsAuthenticated(flag) {
    return {
        type: action_types.AUTH_SET_AUTHENTICATED,
        flag
    }
}

export function setUser(user) {
    if(user){
        localStorage.setItem('elon_user_data', JSON.stringify(user));
    }
    return {
        type: action_types.AUTH_SET_USER,
        user
    }
}

export function init() {
    const user = JSON.parse(localStorage.getItem('elon_user_data'));
    return {
        type: action_types.AUTH_INIT,
        user: user || null,
        isAuthenticated: !!user
    }
}

export function logout() {
    localStorage.removeItem('elon_user_data');
    localStorage.removeItem('current_transaction');
    firebase.instance.auth().signOut();
    return {
        type: action_types.AUTH_LOGOUT,
    }
}


export function signUp() {
    return dispatch => {
        const state = store.getState();
        const email = _.get(state, 'auth.form.email', null);
        const password = _.get(state, 'auth.form.password', null);
        const name = _.get(state, 'auth.form.name', null);
        dispatch(setLoading(true));
        return firebase.instance.auth().createUserWithEmailAndPassword(email, password)
            .then((userCredential) => {

                const token = firebase.instance.auth().currentUser.getIdToken();
                const updateProfile = firebase.instance.auth().currentUser.updateProfile({
                    displayName: name,
                });

                return Promise.all([token, updateProfile]).then((result) => {
                    const [token, updateProfile] = result;
                    dispatch(setUser({ token }));
                    dispatch(setIsAuthenticated(true));
                    return new Promise(resolve => {
                        setTimeout(() => {
                            dispatch(fetchProfile())
                            resolve();
                        }, 2000)
                    })
                });
                  
            }).then(() => {
                dispatch(setLoading(false));
                return 'ok';
            }).catch((error) => {
                let errorCode = error.code;
                let errorMessage = error.message;
                if(errorCode == 'auth/email-already-in-use'){
                    return dispatch(signIn());
                } else {
                    dispatch(putMessage({
                        body: errorMessage
                    }))
                    dispatch(setLoading(false));
                }
            });
    }
}

export function signIn(){
    return dispatch => {
        const state = store.getState();
        const email = _.get(state, 'auth.form.email', null);
        const password = _.get(state, 'auth.form.password', null);
        dispatch(setLoading(true));
        return firebase.instance.auth().signInWithEmailAndPassword(email, password).then(() => {
            const token = firebase.instance.auth().currentUser.getIdToken();
            
            return token.then((result) => {
                dispatch(setUser({ token: result }));
                dispatch(setIsAuthenticated(true));
                dispatch(fetchProfile());
                dispatch(setLoading(false));
                return 'ok';
            });
        }).catch((error) => {
            let errorMessage = error.message;
 
            if(error.code === 'auth/user-not-found'){
                dispatch(putMessage({
                    body: 'Your account information was entered incorrectly'
                }))
            } else if(error.code === 'auth/wrong-password'){
                dispatch(putMessage({
                    body: 'Wrong password'
                }))
            } else {
                dispatch(putMessage({
                    body: errorMessage
                }))
            }

            dispatch(setLoading(false));
        });
    };
}