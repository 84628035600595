import React from 'react';
import { Helmet } from "react-helmet"

export default function Metatags() {
    return (
        <Helmet>
    
            <title>✋💎 ELON COIN 💎✋</title>
            <meta name="title" content="✋💎 ELON COIN 💎✋" />
            <meta name="description" content="Buy once, hold forever. " />


            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://eloncoin.sale/" />
            <meta property="og:title" content="✋💎 ELON COIN 💎✋" />
            <meta property="og:description" content="Buy once, hold forever. " />
            <meta property="og:image" content="/meta_image.png" />


            <meta property="twitter:card" content="summary_large_image" />
            <meta property="twitter:url" content="https://eloncoin.sale/" />
            <meta property="twitter:title" content="✋💎 ELON COIN 💎✋" />
            <meta property="twitter:description" content="Buy once, hold forever. "/>
            <meta property="twitter:image" content="/meta_image.png"/>
            <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png" />
            <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png" />
            <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png"/>
            <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png"/>
            <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png"/>
            <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png"/>
            <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png"/>
            <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png"/>
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png"/>
            <link rel="icon" type="image/png" sizes="192x192"  href="/android-icon-192x192.png"/>
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
            <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png"/>
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
            <link rel="manifest" href="/manifest.json"/>
            <meta name="msapplication-TileColor" content="#ffffff"/>
            <meta name="msapplication-TileImage" content="/ms-icon-144x144.png"/>
            <meta name="theme-color" content="#ffffff"/>
        </Helmet>
    )
}