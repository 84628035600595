import action_types from '../actionTypes';
export function setData(data) {
    return {
        type: action_types.MESSAGE_SET_DATA,
        data
    }
}

export function clear() {
    return {
        type: action_types.MESSAGE_CLEAR,
        
    }
}

export function putMessage(message) {
    return dispatch => {
        dispatch(setData(message));
        setTimeout(() => dispatch(clear()), 3000)
    }
}