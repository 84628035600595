import React from 'react';
import { Toast } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';

export default function Toats() {

    const message = useSelector(state => state.message.message);

    return (
        <Toast
          style={{
            position: 'fixed',
            top: 0,
            right: 0,
            zIndex: 2000
          }}
          show={!!message}
          >
            <Toast.Body><small>{message ? message.body : null}</small></Toast.Body>
        </Toast>
    )
}