import React from "react"
import Header from "./Header"
import Footer from "./Footer"
import Toasts from '../components/Toasts';
import MetaTags from "../components/MetaTags";

export default function Layout({ children }) {

    return (
        <>
            <MetaTags></MetaTags>
            <Header></Header>

            <Toasts />
            <main>{children}</main>
            <Footer></Footer>
        </>
    )
}