import action_types from '../actionTypes';
import api from '../../api';
import storage from '../../api/storage';
import _ from 'lodash';
import store from '../index';

export function setLoading(flag) {
    return {
        type: action_types.INFO_SET_LOADING,
        flag
    }
}

export function setProfileLoading(flag) {
    return {
        type: action_types.INFO_SET_PROFILE_LOADING,
        flag
    }
}

export function setData(data) {
    return {
        type: action_types.INFO_SET_DATA,
        data
    }
}

export function setProfileData(data) {
    return {
        type: action_types.INFO_SET_PROFILE_DATA,
        data
    }
}

export function setRedirectParams(data) {
    return {
        type: action_types.INFO_SET_REDIRECT_DATA,
        data
    }
}

export function clearRedirectData() {
    return {
        type: action_types.INFO_CLEAR_REDIRECT_DATA
    }
}

export function fetch() {
    return dispatch => {
        dispatch(setLoading(true))
        return storage.fetch().then(res => { 
            const total = _.get(res, 'data.remaining', null);;
            //const maxCount = _.get(res, 'data.data.max_quantity', null);
           // const currentPrice = _.get(res, 'data.data.price', null);
            //const minQty = _.get(res, 'data.data.min_quantity', null);
            //const maxQty = _.get(res, 'data.data.max_quantity', null);
            // dispatch(setData({
            //     total: maxCount,
            //     price: currentPrice,
            //     minQty,
            //     maxQty
            // }))
            dispatch(setData({
                total: total,
                maxQty: total
            }))
            dispatch(setLoading(false))
        }).catch(() => {
            dispatch(setLoading(false))
        })
    }
}

export function fetchProfile(){
    return dispatch => {
        dispatch(setProfileLoading(true));
        return api.iam().then(res => {
            dispatch(setProfileData(res.data))
            dispatch(setProfileLoading(false));
        }).catch(e => {
            dispatch(setProfileLoading(false));
        })
    }
}