import axios from 'axios';
import store from '../store';
import _ from 'lodash';
import { navigate } from 'gatsby';
import { logout } from '../store/actions/auth';
import { putMessage } from '../store/actions/message';

const URL = process.env.GATSBY_API_URL;
const axiosInstance = axios.create({
    baseURL: URL,
})

axiosInstance.interceptors.request.use(function (config) {
    const state = store.getState();
    const token = _.get(state, 'auth.user.token', null);

    if(token){
        config.headers['Authorization'] = 'Bearer ' + token;
    }

    return config;
});

axiosInstance.interceptors.response.use(function (response) {
    return response
}, function (error) {
    const status = _.get(error, 'response.status', null);
    const state = store.getState();
    const token = _.get(state, 'auth.user.token', null);

    if(status == 403 && token){
        store.dispatch(logout());
        store.dispatch(putMessage({ 
            body: 'Your session has timed out'
        }));
        navigate('/')
    }

    return Promise.reject(error);
});

export default {
    info(){
        return axiosInstance.get('/');
    },
    iam(){
        return axiosInstance.get('/iam')
    },
    buy(params){
        return axiosInstance.post('/buy', params)
    }
}