import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { setFormValue } from '../store/actions/auth';

export default function AuthForm(props){
    const dispatch = useDispatch();
    
    const email = useSelector(state => state.auth.form.email );
    const pass = useSelector(state => state.auth.form.password );
    const name = useSelector(state => state.auth.form.name );

    const handleEnterPress = (e) => {
        if(e.key === 'Enter' && props.handleSubmit){
            props.handleSubmit()
        }
    } 

    return (
        <div className="FormGroup">
            {!props.hideName && 
                <Form.Group controlId="user-name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control value={name} onChange={(e) => dispatch(setFormValue('name',e.target.value))} type="text" placeholder="Enter your First & Last name"  />
                </Form.Group>
            }
            <Form.Group controlId="user-email">
                <Form.Label>Email</Form.Label>
                <Form.Control value={email} onChange={(e) =>dispatch(setFormValue('email',e.target.value))}  type="email" placeholder="Enter email" />
            </Form.Group>
            <Form.Group controlId="user-password">
                <Form.Label>Password</Form.Label>
                <Form.Control onKeyPress={handleEnterPress} value={pass} onChange={(e) => dispatch(setFormValue('password',e.target.value))}  type="password" placeholder="Create password" />
            </Form.Group>
        </div>
    )
}